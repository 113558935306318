<template>
    <el-drawer
        :title="title"
        class="custom_drawer"
        :visible.sync="isShow"
        size="640px"
        :before-close="onClose"
        @opened="onOpened"
    >
        <el-form v-if="formData" ref="formData" :model="formData" :rules="rules" label-width="80px">
            <el-form-item label="项目" prop="projectNo">
                <ProjectSelect :defaultValue="formData.projectNo" @change="(val) => (formData.projectNo = val)" />
            </el-form-item>
            <el-form-item label="标题" prop="title">
                <el-input placeholder="请输入" :maxlength="32" show-word-limit v-model.trim="formData.title" />
            </el-form-item>
            <el-form-item label="封面图" prop="bannerImageUrl">
                <el-upload
                    class="upload_image"
                    action=""
                    accept="image/*"
                    :show-file-list="false"
                    :before-upload="onBannerImageUrlUpload"
                >
                    <i v-if="!formData.bannerImageUrl" class="el-icon-picture" />
                    <el-image v-else :src="formData.bannerImageUrl" fit="cover" />
                    <div slot="tip" class="el-upload__tip">推荐比例 16:9</div>
                </el-upload>
            </el-form-item>
            <el-form-item label="内容介绍" prop="descriptionImageUrl">
                <el-upload
                    class="upload_image"
                    action=""
                    accept="image/*"
                    :show-file-list="false"
                    :before-upload="onDescriptionImageUrlUpload"
                >
                    <i v-if="!formData.descriptionImageUrl" class="el-icon-picture" />
                    <el-image v-else :src="formData.descriptionImageUrl" fit="cover" />
                </el-upload>
            </el-form-item>
            <el-form-item label="付费学员" prop="allowVipFlag">
                <el-radio-group v-model="formData.allowVipFlag">
                    <el-radio-button :label="false">禁止选课</el-radio-button>
                    <el-radio-button :label="true">允许选课</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="收集信息">
                <el-checkbox-group v-model="formData.collectsList">
                    <el-checkbox label="tel">手机号</el-checkbox>
                </el-checkbox-group>
            </el-form-item>

            <el-form-item label="备注" prop="remark">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 4 }"
                    :maxlength="128"
                    show-word-limit
                    placeholder="请输入备注"
                    v-model="formData.remark"
                />
            </el-form-item>
        </el-form>

        <div class="drawer_footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确定</el-button>
        </div>
    </el-drawer>
</template>
<script>
import ProjectSelect from '@/views/components/ProjectSelect'
import { addCoursePackage, editCoursePackage } from '@/js/api/courseApi.js'
import { imageCompressor, UPLOAD_FILE } from '@/js/aliOss.js'

export default {
    props: ['isShow', 'activeItem', 'projectNo'],
    components: { ProjectSelect },
    data() {
        return {
            title: '',
            formData: null,
            rules: {
                title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
                bannerImageUrl: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
                descriptionImageUrl: [{ required: true, message: '请上传图片', trigger: 'blur' }],
            },
        }
    },
    methods: {
        onOpened() {
            this.title = '新建公开课'
            let formData = {
                projectNo: this.projectNo,
                title: '',
                collectsList: ['tel'],
                bannerImageUrl: '',
                descriptionImageUrl: '',
                provider: 'talkfun',
                ptype: 'OPEN_LIVE_ROUND',
                allowVipFlag: false,
                remark: '',
            }

            if (this.activeItem) {
                this.title = `${this.activeItem.title} - 编辑`
                formData = { ...formData, ...this.activeItem }
                formData.collectsList = formData.collects ? formData.collects.split(',') : []
            }
            this.formData = formData
        },
        onClose() {
            this.title = ''
            this.formData = null
            this.$emit('onClose')
        },
        async onBannerImageUrlUpload(file) {
            const _file = await imageCompressor(file)
            UPLOAD_FILE({
                bucket: 'res',
                file: _file,
                path: 'resource/',
                success: (res) => {
                    this.formData.bannerImageUrl = res.url
                    this.$refs.formData.validateField(['bannerImageUrl'])
                },
            })
            return false
        },
        async onDescriptionImageUrlUpload(file) {
            const _file = await imageCompressor(file)
            UPLOAD_FILE({
                bucket: 'res',
                file: _file,
                path: 'resource/',
                success: (res) => {
                    this.formData.descriptionImageUrl = res.url
                    this.$refs.formData.validateField(['descriptionImageUrl'])
                },
            })
            return false
        },
        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    const _params = {
                        ...this.formData,
                        collects: this.formData.collectsList.join(','),
                    }
                    if (_params.packageNo) {
                        editCoursePackage(_params).then(() => {
                            this.onClose()
                            this.$emit('onClose', true)
                            this.$message.success('修改成功')
                        })
                    } else {
                        addCoursePackage(_params).then(() => {
                            this.onClose()
                            this.$emit('onClose', true)
                            this.$message.success('创建成功')
                        })
                    }
                }
            })
        },
    },
}
</script>
