<template>
    <div>
        <el-drawer :title="title" :visible.sync="isShow" size="960px" :before-close="onClose" @opened="onOpened">
            <div class="header">
                <el-button v-if="P(1030101)" @click="onShowEditCourseUnit(null)" type="primary">创建直播</el-button>
            </div>
            <el-table :data="tableData" border stripe height="calc(100% - 110px)">
                <el-table-column align="center" prop="courseNo" label="编号" width="72" />
                <el-table-column header-align="center" prop="title" label="标题" />
                <el-table-column align="center" label="讲师" width="72">
                    <template slot-scope="scope">
                        <span v-if="teacherMap">
                            {{ teacherMap[scope.row.teacherId] ? teacherMap[scope.row.teacherId]['name'] : '-' }}
                        </span>
                        <span v-else>{{ scope.row.teacherId || '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="liveId" label="直播ID" width="72" />
                <el-table-column align="center" prop="startTime" label="开始时间" width="96" />
                <el-table-column align="center" prop="endTime" label="结束时间" width="96" />

                <el-table-column align="center" label="直播间" width="100">
                    <template slot-scope="scope">
                        <el-link v-if="P(1030106)" @click="onShowLiveSellPush(scope.row)" type="primary" size="mini">
                            直播推送
                        </el-link>
                        <el-link @click="onShowLiveUrl(scope.row)" type="primary" size="mini">直播间详情</el-link>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-link v-if="P(1030101)" @click="onShowEditCourseUnit(scope.row)" type="primary">
                            修改课程
                        </el-link>
                        <el-link v-if="P(1030107)" @click="onShowAttendance(scope.row)" type="info">出勤数据</el-link>
                        <el-link v-if="P(1030108)" @click="onShowAuditionCourse(scope.row)" type="info">
                            试听地址
                        </el-link>
                        <el-popconfirm v-if="P(1030102)" title="确定删除吗？" @confirm="onDelCourse(scope.row)">
                            <el-link slot="reference" type="danger">删除直播</el-link>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </el-drawer>

        <EditCourseUnit
            defaultProvider="talkfun"
            :packageInfo="activeItem"
            :activeItem="activeCourse"
            :isShow="isShowEditCourseUnit"
            @onClose="onCloseEditCourseUnit"
        />

        <LiveSellPush :activeCourse="activeCourse" :isShow.sync="isShowLiveSellPush" @onClose="onCloseLiveSellPush" />
        <LiveUrl
            :packageInfo="activeItem"
            :activeCourse="activeCourse"
            :isShow.sync="isShowLiveUrl"
            @onClose="onCloseLiveUrl"
        />
        <AuditionCourse
            :packageInfo="activeItem"
            :activeCourse="activeCourse"
            :isShow.sync="isShowAuditionCourse"
            @onClose="onCloseAuditionCourse"
        />
        <Attendance :isShow="isShowAttendance" :activeCourse="activeCourse" @onClose="onCloseAttendance" />
    </div>
</template>
<script>
import EditCourseUnit from './EditCourseUnit.vue'
import LiveSellPush from './LiveSellPush.vue'
import LiveUrl from '../LiveUrl.vue'
import AuditionCourse from './AuditionCourse.vue'
import Attendance from '../Attendance.vue'
import { getCourseList, delCourse } from '@/js/api/courseApi.js'
import { getCourseTeacher } from '@/js/api/courseApi.js'
import { mapGetters } from 'vuex'

export default {
    props: ['isShow', 'activeItem'],
    computed: { ...mapGetters(['userInfo']) },
    components: { LiveSellPush, LiveUrl, EditCourseUnit, AuditionCourse, Attendance },
    data() {
        return {
            teacherMap: null,

            title: '',

            tableData: [],

            activeCourse: null,
            isShowLiveUrl: false,
            isShowLiveSellPush: false,
            isShowEditCourseUnit: false,
            isShowAuditionCourse: false,
            isShowAttendance: false,
        }
    },
    methods: {
        onOpened() {
            this.title = this.activeItem.title
            this.getCoursePackageMappingListApi()
            this.getCourseTeacherApi()
        },
        onClose() {
            this.title = ''
            this.$emit('onClose')
        },
        onPaginationChange(type, value) {
            if (type === 'size') {
                this.pageSize = value
                this.page = 1
            } else {
                this.page = value
            }
            this.$nextTick(this.getCoursePackageMappingListApi)
        },
        // 编辑课程
        onShowEditCourseUnit(item) {
            this.activeCourse = item
            this.isShowEditCourseUnit = true
        },
        onCloseEditCourseUnit(status) {
            this.activeCourse = null
            this.isShowEditCourseUnit = false
            if (status) this.getCoursePackageMappingListApi()
        },

        // 直播地址
        onShowLiveUrl(item) {
            this.activeCourse = item
            this.isShowLiveUrl = true
        },
        onCloseLiveUrl() {
            this.activeCourse = null
            this.isShowLiveUrl = false
        },

        // 直播推送
        onShowLiveSellPush(item) {
            this.activeCourse = item
            this.isShowLiveSellPush = true
        },
        onCloseLiveSellPush() {
            this.activeCourse = null
            this.isShowLiveSellPush = false
        },

        // 出勤数据
        onShowAttendance(item) {
            this.activeCourse = item
            this.isShowAttendance = true
        },
        onCloseAttendance() {
            this.activeCourse = null
            this.isShowAttendance = false
        },

        // 试听地址
        onShowAuditionCourse(item) {
            this.activeCourse = item
            this.isShowAuditionCourse = true
        },
        onCloseAuditionCourse() {
            this.activeCourse = null
            this.isShowAuditionCourse = false
        },

        // 删除课程
        onDelCourse(item) {
            delCourse({ staffId: this.userInfo.id, courseId: item.id }).then(() => {
                this.$message.success('删除成功')
                this.getCoursePackageMappingListApi()
            })
        },

        async getCoursePackageMappingListApi() {
            this.tableData = []
            const res1 = await getCourseList({ packageId: this.activeItem.id, showPage: false })
            this.tableData = res1.data
        },
        getCourseTeacherApi() {
            getCourseTeacher({}).then((res) => {
                const _map = {}
                res.data.forEach((item) => {
                    _map[item.id] = item
                })
                this.teacherMap = _map
            })
        },
    },
}
</script>
<style lang="less" scoped>
.header {
    margin-bottom: 20px;
}
</style>
