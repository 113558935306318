<template>
    <el-dialog
        title="编辑课程"
        :visible.sync="isShow"
        width="500px"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="onClose"
        @opened="onOpened"
    >
        <el-form v-if="formData" ref="formData" :model="formData" :rules="rules" label-width="100px">
            <template v-if="formData.provider === 'video'">
                <el-form-item label="本地视频" prop="courseUrl">
                    <UploadVideo :videoUrl="formData.courseUrl" @change="onVideoUrlUpload" />
                </el-form-item>
                <el-form-item label="视频时长(s)" prop="courseDuration">
                    <el-input-number
                        placeholder="待生成"
                        disabled
                        v-model="formData.courseDuration"
                        :controls="false"
                    />
                </el-form-item>
            </template>
            <template v-else-if="formData.provider === 'talkfun'">
                <el-form-item label="直播类型" prop="mirageType">
                    <el-select
                        v-model="formData.mirageType"
                        @change="onMirageTypeChange"
                        :disabled="!!formData.id"
                        placeholder="请选择"
                    >
                        <el-option label="普通直播" :value="0" />
                        <el-option label="互动直播" :value="2" />
                        <el-option label="伪直播" :value="1" />
                    </el-select>
                </el-form-item>
                <template v-if="formData.mirageType === 1">
                    <el-form-item label="伪直播模版" prop="mirageTplId">
                        <el-select
                            :disabled="!!formData.id"
                            v-model="formData.mirageTplId"
                            filterable
                            remote
                            placeholder="请输入关键词进行搜索"
                            :remote-method="getCourseTplListApi"
                            :loading="courseTplLoading"
                            @change="onMirageTplIdChange"
                        >
                            <el-option-group label="显示前20条">
                                <el-option
                                    v-for="item in courseTplList"
                                    :key="item.id"
                                    :label="item.title"
                                    :value="item.id"
                                />
                            </el-option-group>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="推送商品" prop="mirageProductNo" v-if="isShowMirageProductNo">
                        <ProductSelect
                            :defaultValue="formData.mirageProductNo"
                            :clearable="true"
                            @change="(val) => (formData.mirageProductNo = val)"
                        />
                    </el-form-item>
                </template>
                <el-form-item label="开始时间" prop="startTime">
                    <el-date-picker
                        v-model="formData.startTime"
                        type="datetime"
                        placeholder="选择开始时间"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="endTime">
                    <el-date-picker
                        v-model="formData.endTime"
                        type="datetime"
                        placeholder="选择结束时间"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    />
                </el-form-item>
            </template>
            <el-form-item label="课程名称" prop="title">
                <el-input placeholder="请输入" v-model.trim="formData.title" :maxlength="32" show-word-limit />
            </el-form-item>
            <el-form-item label="讲师" prop="teacherId">
                <el-select v-model="formData.teacherId">
                    <el-option
                        v-for="item in teacherList"
                        :key="item.id"
                        :label="`${item.virtualFlag ? '[伪]' : ''}${item.name}`"
                        :style="item.virtualFlag ? 'color: #aaa' : ''"
                        :value="item.id"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="封面图" prop="coverUrl">
                <el-upload
                    class="upload_image"
                    action=""
                    accept="image/*"
                    :show-file-list="false"
                    :before-upload="onCoverUrlUpload"
                >
                    <i v-if="!formData.coverUrl" class="el-icon-picture" />
                    <el-image v-else :src="formData.coverUrl" fit="cover" />
                    <div slot="tip" class="el-upload__tip">推荐比例 3:2</div>
                </el-upload>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input
                    :autosize="{ minRows: 3, maxRows: 4 }"
                    :maxlength="128"
                    show-word-limit
                    type="textarea"
                    placeholder="请输入"
                    v-model.trim="formData.remark"
                />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { courseProviderList } from '@/js/config/course.js'
import { addCourse, editCourse, getCourseTplList, getCourseTeacher } from '@/js/api/courseApi'
import { imageCompressor, UPLOAD_FILE } from '@/js/aliOss.js'
import UploadVideo from '@/views/components/UploadVideo.vue'
import ProductSelect from '@/views/components/ProductSelect'

export default {
    props: ['isShow', 'activeItem', 'defaultProvider', 'packageInfo'],
    components: { UploadVideo, ProductSelect },
    data() {
        return {
            courseProviderList,
            formData: null,
            rules: {
                title: [{ required: true, message: '请输入名称', trigger: 'blur' }],
                teacherId: [{ required: true, message: '请选择讲师', trigger: 'blur' }],
                provider: [{ required: true, message: '请选择课程类型', trigger: 'blur' }],
                mirageType: [{ required: true, message: '请选择直播类型', trigger: 'blur' }],
                coverUrl: [{ required: true, message: '请上传封面', trigger: 'blur' }],
                courseDuration: [{ required: true, message: '请上传视频', trigger: 'blur' }],
                courseUrl: [{ required: true, message: '请上传视频', trigger: 'blur' }],
                startTime: [{ required: true, message: '请输入开始时间', trigger: 'blur' }],
                endTime: [{ required: true, message: '请输入结束时间', trigger: 'blur' }],
                mirageTplId: [{ required: true, message: '请选择伪直播模版', trigger: 'blur' }],
                mirageProductNo: [{ required: true, message: '请选择推送商品', trigger: 'blur' }],
            },
            teacherList: [],
            courseTplList: [],
            courseTplLoading: false,
            isShowMirageProductNo: false,
        }
    },
    methods: {
        onOpened() {
            let formData = {
                title: '',
                teacherId: '',
                mirageType: '',
                coverUrl: '',
                courseUrl: '',
                endTime: '',
                provider: this.defaultProvider || '',
                startTime: '',
                mirageTplId: '',
                mirageProductNo: '',
                courseDuration: '',
                remark: '',
            }
            if (this.activeItem) {
                formData = { ...formData, ...this.activeItem }
                getCourseTplList({ mirageTplId: formData.mirageTplId }).then((res) => {
                    this.courseTplList = res.data
                    this.onShowMirageProductNo()
                })

                if (this.activeItem.extras) {
                    this.activeItem.extras.forEach((item) => {
                        if (item.extraType === 'sellProductNo') {
                            formData.mirageProductNo = item.extraValue
                        }
                    })
                }
            } else {
                this.getCourseTplListApi('')
            }
            this.formData = formData
            this.getCourseTeacherApi()
        },
        onClose() {
            this.$emit('onClose')
        },
        async onCoverUrlUpload(file) {
            const _file = await imageCompressor(file)
            UPLOAD_FILE({
                bucket: 'res',
                file: _file,
                path: 'resource/',
                success: (res) => {
                    this.formData.coverUrl = res.url
                    this.$refs.formData.validateField(['coverUrl'])
                },
            })
            return false
        },
        onVideoUrlUpload(video) {
            this.formData.courseUrl = video.url
            this.formData.courseDuration = video.duration || 0
            this.$refs.formData.validateField(['courseUrl'])
        },
        onMirageTypeChange() {
            this.formData.mirageTplId = ''
            this.formData.mirageProductNo = ''
            this.onShowMirageProductNo()
        },
        onMirageTplIdChange() {
            this.formData.mirageProductNo = ''
            this.onShowMirageProductNo()
        },

        onSubmit() {
            this.$refs.formData.validate((valid) => {
                const params = { ...this.formData }
                if (valid) {
                    if (params.mirageProductNo) {
                        params.extras = [{ extraType: 'sellProductNo', extraValue: params.mirageProductNo }]
                    }
                    if (!params.id) {
                        const _packageId = this.packageInfo ? this.packageInfo.id : ''
                        addCourse(params, _packageId).then((res) => {
                            this.$message.success('创建成功')
                            this.$emit('onClose', true, res.data.id)
                        })
                    } else {
                        editCourse(params).then(() => {
                            this.$message.success('修改成功')
                            this.$emit('onClose', true)
                        })
                    }
                }
            })
        },
        getCourseTeacherApi() {
            getCourseTeacher({}).then((res) => {
                this.teacherList = res.data
            })
        },
        getCourseTplListApi(query) {
            const params = { page: 1, size: 20, showPage: false }
            if (query) params.title = query
            this.courseTplLoading = true
            getCourseTplList(params).then((res) => {
                this.courseTplLoading = false
                this.courseTplList = res.data
                this.onShowMirageProductNo()
            })
        },
        onShowMirageProductNo() {
            this.isShowMirageProductNo = false
            this.courseTplList.forEach((i) => {
                const _action = i.action ? JSON.parse(i.action) : []
                _action.forEach((item) => {
                    if (this.formData.mirageTplId === i.id && item.action === 'sell_product_pop') {
                        this.isShowMirageProductNo = true
                    }
                })
            })
        },
    },
}
</script>
<style lang="less" scoped>
.el-select {
    width: 100%;
}
</style>
