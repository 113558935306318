<template>
    <el-drawer :title="title" :visible.sync="isShow" size="720px" :before-close="onClose" @opened="onOpened">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item" style="width: 50%">
                    <span class="condition_content">
                        <el-input
                            placeholder="请输入内容"
                            @change="onUserIdChange"
                            clearable
                            v-model.trim="queryTypeValue"
                            @keyup.enter.native="onPaginationChange('page', 1)"
                        >
                            <el-select style="width: 100px" v-model="queryType" slot="prepend" placeholder="请选择">
                                <el-option label="学员昵称" value="keyWord"></el-option>
                                <el-option label="学员ID" value="userId"></el-option>
                            </el-select>
                        </el-input>
                    </span>
                </div>
                <div class="condition_item" style="width: 50%">
                    <span class="condition_label">选课渠道</span>
                    <span class="condition_content">
                        <StaffSelect @itemChange="onStaffChange" :clearable="true" />
                    </span>
                </div>
            </div>
            <div class="opt_container">
                <el-button @click="onReset">重置</el-button>
                <el-button @click="onPaginationChange('page', 1)" type="primary">查询</el-button>
                <ExportFile
                    class="float_left_btn"
                    host="begonia"
                    path="/coursePackageUser/export"
                    @before-export="onBeforeExport"
                    v-if="P(1030104)"
                />
            </div>
        </div>
        <el-table :data="tableData" stripe border>
            <el-table-column align="center" width="160" prop="receiveTime" label="报名时间" />
            <el-table-column header-align="center" label="微信信息">
                <template slot-scope="scope">
                    <UserView v-if="scope.row.user" :key="scope.row.id" :userInfo="scope.row.user" />
                    <span v-else>{{ scope.row.userId }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="center" prop="channel" width="100" label="选课渠道">
                <template slot-scope="scope">
                    <div>
                        <span v-if="scope.row.sourceType === 4">{{ scope.row.sourceFrom }}</span>
                        <StaffName v-else-if="scope.row.sourceType === 2" :staff="scope.row.staff" />
                        <StaffName v-else-if="scope.row.channel" :staff="scope.row.staff" />
                        <span v-else>-</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            background
            :page-sizes="[10, 20, 50, 100]"
            @size-change="(val) => onPaginationChange('size', val)"
            @current-change="(val) => onPaginationChange('page', val)"
            :current-page.sync="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, sizes"
            :total="total"
        />
    </el-drawer>
</template>

<script>
import StaffSelect from '@/views/components/StaffSelect'
import UserView from '@/views/components/UserView'
import { getStaffList } from '@/js/api/staffApi'
import { getSignUpList } from '@/js/api/courseApi.js'
import { filterQueryEmpty, isUserId } from '@/js/utils.js'

export default {
    props: ['isShow', 'activeItem'],
    components: { UserView, StaffSelect },
    data() {
        return {
            title: '',

            channel: '',
            queryType: 'keyWord',
            queryTypeValue: '',

            tableData: [],
            page: 1,
            pageSize: 10,
            total: 0,
        }
    },
    methods: {
        onOpened() {
            this.title = `${this.activeItem.title} - 选课详情`
            this.getSignUpListApi()
        },
        onClose() {
            this.title = ''
            this.queryTypeValue = ''
            this.channel = ''
            this.tableData = []

            this.$emit('onClose')
        },
        onReset() {
            this.queryTypeValue = ''
            this.channel = ''

            this.onPaginationChange('page', 1)
        },

        onUserIdChange() {
            if (this.queryType === 'userId' && this.queryTypeValue && !isUserId(this.queryTypeValue)) {
                this.queryTypeValue = ''
                this.$message.warning('请输入正确的 学员ID')
            }
        },
        onBeforeExport(cb) {
            let params = {
                channel: this.channel,
                packageId: this.activeItem.id,
            }
            params[this.queryType] = this.queryTypeValue
            params = filterQueryEmpty(params)
            cb(params)
        },

        onStaffChange(staff) {
            this.channel = staff ? staff.staffNo : ''
        },
        onPaginationChange(type, value) {
            if (type === 'size') {
                this.pageSize = value
                this.page = 1
            } else {
                this.page = value
            }
            this.$nextTick(this.getSignUpListApi)
        },
        async getSignUpListApi() {
            const { page, pageSize, channel } = this
            let params = {
                page,
                size: pageSize,
                channel,
                packageId: this.activeItem.id,
                orderBy: '-id',
                showPage: true,
            }
            params[this.queryType] = this.queryTypeValue
            params = filterQueryEmpty(params)
            const res1 = await getSignUpList(params)
            if (res1.data.list.length !== 0) {
                const _staffNos = []
                res1.data.list.forEach((i) => {
                    if (i.sourceType === 2) {
                        if (!_staffNos.includes(i.sourceFrom)) {
                            _staffNos.push(i.sourceFrom)
                        }
                    } else if (i.channel) {
                        _staffNos.push(i.channel)
                    }
                })
                const res2 = await getStaffList({ staffNos: _staffNos })
                const _map = {}
                res2.data.forEach((staff) => {
                    _map[staff.staffNo] = staff
                })
                res1.data.list.forEach((i) => {
                    if (i.sourceType === 2) {
                        i.staff = _map[i.sourceFrom] || {}
                    } else if (i.channel) {
                        i.staff = _map[i.channel] || {}
                        _staffNos.push(i.channel)
                    }
                })
            }
            this.tableData = res1.data.list
            this.total = res1.data.total
            this.page = res1.data.pageNum
        },
    },
}
</script>
