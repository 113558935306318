<template>
    <el-drawer :title="title" :visible.sync="isShow" size="640px" :before-close="onClose" @opened="onOpened">
        <div class="title">弹幕消息</div>
        <div class="purchase opt_item">
            <el-input v-model="purchaseNickname" :maxlength="32" show-word-limit placeholder="默认随机" />
            <span class="text">购买了</span>
            <div class="action">
                <ProductSelect :clearable="true" @itemChange="onPurchaseProductChange" />
            </div>
            <el-button @click="onSendMessage('to_pay')" plain type="primary">发送消息</el-button>
        </div>
        <div class="reward opt_item">
            <el-input v-model="rewardNickname" :maxlength="32" show-word-limit placeholder="默认随机" />
            <span class="text">打赏了</span>
            <el-select class="action" v-model="rewardNum" placeholder="打赏金额">
                <el-option
                    v-for="item in ['2.00', '6.66', '8.88', '16.66', '18.88']"
                    :key="item"
                    :label="item + '元'"
                    :value="item + '元'"
                />
            </el-select>
            <el-button @click="onSendMessage('reward')" plain type="primary">发送消息</el-button>
        </div>
        <div class="title">售卖商品</div>
        <div class="opt_item">
            <ProductSelect :clearable="true" @itemChange="(val) => (productId = val.id)" />
            <el-popconfirm title="确定添加吗？" :disabled="!productId" @confirm="onSelectPackageNo">
                <el-button slot="reference" :disabled="!productId" type="primary">添加商品</el-button>
            </el-popconfirm>
        </div>
        <el-table :data="tableData" border style="width: 100%">
            <el-table-column align="center" prop="productNo" label="编号" width="80" />
            <el-table-column header-align="center" prop="title" label="推送商品" />
            <el-table-column align="center" prop="price" label="价格">
                <template slot-scope="scope">
                    <span>{{ scope.row.price ? scope.row.price / 100 : 0 }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="center" prop="remark" label="备注" />
            <el-table-column align="center" label="操作" width="64">
                <template slot-scope="scope">
                    <div class="mini_link">
                        <el-link type="primary" @click="onLiveSellRefresh(scope.row)">更新</el-link>
                        <el-link type="primary" @click="onLiveSellPush(scope.row)">弹窗</el-link>
                        <el-popconfirm title="确定删除吗？" @confirm="onRollback(scope.row)">
                            <el-link type="danger" slot="reference">删除</el-link>
                        </el-popconfirm>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </el-drawer>
</template>

<script>
import { getLiveProduct, liveSellPush, liveSellRollback, liveSellRefresh, liveMessagePush } from '@/js/api/courseApi.js'
import ProductSelect from '@/views/components/ProductSelect'

export default {
    props: ['isShow', 'activeCourse'],
    components: { ProductSelect },
    data() {
        return {
            title: '',

            purchaseNickname: '',
            purchaseProduct: null,
            rewardNickname: '',
            rewardNum: '',

            productId: '',
            tableData: [],
        }
    },
    methods: {
        onOpened() {
            this.title = `${this.activeCourse.title} - 直播推送`
            this.liveCoursePackageApi()
        },

        onClose() {
            this.title = ''
            this.purchaseNickname = ''
            this.purchaseProduct = null
            this.rewardNickname = ''
            this.rewardNum = ''

            this.productId = ''
            this.tableData = []
            this.$emit('onClose')
        },

        onPurchaseProductChange(val) {
            this.purchaseProduct = val
        },

        onSendMessage(msgType) {
            const params = { type: msgType, courseId: this.activeCourse.id }
            if (msgType === 'to_pay') {
                if (!this.purchaseProduct) return this.$message.warning('请选择购买商品')
                params.nickname = this.purchaseNickname
                params.action = '正在购买'
                params.target = this.purchaseProduct.title
            } else if (msgType === 'reward') {
                if (!this.rewardNum) return this.$message.warning('请选择打赏金额')
                params.nickname = this.rewardNickname
                params.action = '打赏了'
                params.target = this.rewardNum
            }
            liveMessagePush(params).then(() => {
                this.$message.success('发送成功')
            })
        },
        onLiveSellPush(item) {
            liveSellPush({ courseId: this.activeCourse.id, productId: item.id }).then(() => {
                this.$message.success('推送成功')
            })
        },
        onRollback(item) {
            liveSellRollback({ courseId: this.activeCourse.id, productId: item.id }).then(() => {
                this.$message.success('商品已撤销')
                this.liveCoursePackageApi()
            })
        },
        onLiveSellRefresh(item) {
            liveSellRefresh({ courseId: this.activeCourse.id, productId: item.id }).then(() => {
                this.$message.success('商品已更新')
            })
        },
        onSelectPackageNo() {
            liveSellPush({ courseId: this.activeCourse.id, productId: this.productId }).then(() => {
                this.liveCoursePackageApi()
                this.packageId = ''
                this.$message.success('推送成功')
            })
        },
        liveCoursePackageApi() {
            getLiveProduct({ courseId: this.activeCourse.id }).then((res) => {
                this.tableData = res.data
            })
        },
    },
}
</script>

<style lang="less" scoped>
.title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 16px;
}
.opt_item {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    .el-input {
        width: 120px;
        flex-shrink: 0;
    }
    .text {
        flex-shrink: 0;
        margin: 0 20px;
    }
    .action {
        flex-grow: 1;
    }
    .el-button {
        margin: 0 20px;
        flex-shrink: 0;
    }
}
.liveSellPush {
    .flex_content {
        display: flex;
        .el-cascader {
            flex-grow: 1;
            margin-right: 20px;
        }
    }
    .add_productNo {
        margin: 20px 0;
    }
    .el-cascader {
        width: 100%;
    }
    .el-link {
        margin-right: 10px;
    }
}
.el-select {
    min-width: 300px;
}
</style>
