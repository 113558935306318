<template>
    <el-dialog
        class="live_url_dialog"
        title="直播间详情"
        :visible.sync="isShow"
        :before-close="onClose"
        @opened="onOpened"
    >
        <div class="live_url" v-for="item in liveUrlList" :key="item.key">
            <span class="label">{{ item.label }}:</span>
            <span class="content">
                <el-input :value="item.url" />
                <el-link type="primary" @click="onCopyUrl(item.url)">复制</el-link>
                <el-link target="_blank" :href="item.url" type="info">预览</el-link>
            </span>
        </div>
        <div class="lottery" v-if="lotteryList.length !== 0">
            <el-table size="mini" border :data="lotteryList" :key="lotteryKey" max-height="400px">
                <el-table-column prop="prize" label="奖品" width="96" />
                <el-table-column prop="lotteryTime" label="抽奖时间" width="96" />
                <el-table-column prop="address" label="中奖人">
                    <template slot-scope="scope">
                        <div class="userInfo">
                            <el-avatar :size="42" shape="square" :src="scope.row.user.avatar" />
                            <div>
                                <span>ID: {{ scope.row.user.id }}</span>
                                <span>{{ scope.row.user.nickname }}</span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="归属" width="72">
                    <template slot-scope="scope">
                        <span v-if="lotteryStaff[scope.row.userId]">{{ lotteryStaff[scope.row.userId] }}</span>
                        <el-link @click="onQueryStaff(scope.row)" v-else type="primary">查询</el-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </el-dialog>
</template>
<script>
import { getCourseLiveUrl, liveLottery } from '@/js/api/courseApi.js'
import { getUserJudgeBelong } from '@/js/api/userApi.js'
import { filterQueryEmpty } from '@/js/utils.js'
import { v4 as uuidv4 } from 'uuid'

export default {
    props: ['isShow', 'activeCourse', 'packageInfo'],
    data() {
        return {
            liveUrlList: [],

            lotteryList: [],
            lotteryKey: '',
            lotteryStaff: {},
        }
    },
    methods: {
        onOpened() {
            this.getCourseLiveUrlApi()
            this.liveLotteryApi()
        },
        onClose() {
            this.liveUrlList = []
            this.lotteryList = []
            this.$emit('onClose')
        },

        onCopyUrl(url) {
            window.navigator.clipboard.writeText(url).then(() => {
                this.$message.success('复制成功')
            })
        },
        onQueryStaff(item) {
            getUserJudgeBelong({ userId: item.userId }).then((res) => {
                let staffName = '-'
                if (res.data.optStaff) {
                    staffName = res.data.optStaff.name
                }
                this.lotteryStaff[item.userId] = staffName
                this.lotteryKey = uuidv4()
            })
        },

        getCourseLiveUrlApi() {
            let params = {
                courseId: this.activeCourse ? this.activeCourse.id : '',
                packageId: this.packageInfo ? this.packageInfo.id : '',
            }
            params = filterQueryEmpty(params)
            getCourseLiveUrl(params).then((res) => {
                const _labelNameMap = {
                    sAdminUrl: { name: '超管地址', seq: 1 },
                    adminUrl: { name: '助教登陆', seq: 2 },
                    stuLiveUrl: { name: '学员直播地址', seq: 3 },
                    stuReplayUrl: { name: '学员回放地址', seq: 4 },
                }
                const _keys = Object.keys(res.data)
                this.liveUrlList = _keys.map((k) => {
                    return {
                        key: k,
                        label: _labelNameMap[k]['name'],
                        url: res.data[k],
                        seq: _labelNameMap[k]['seq'],
                    }
                })
                this.liveUrlList.sort((a, b) => a.seq - b.seq)
            })
        },
        liveLotteryApi() {
            liveLottery({ courseId: this.activeCourse.id }).then((res) => {
                this.lotteryList = res.data || []
            })
        },
    },
}
</script>
<style lang="less" scoped>
.live_url_dialog {
    /deep/ .el-dialog {
        max-width: 720px;
        min-width: 540px;
        width: 90%;
    }
}
.live_url {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 20px;
    .label {
        width: 90px;
        text-align: right;
        font-size: 13px;
        margin-right: 12px;
    }
    .content {
        display: flex;
        flex-grow: 1;
        align-items: center;
    }
    .el-input {
        flex-grow: 1;
    }
    .el-link {
        flex-shrink: 0;
        margin-left: 10px;
    }
}
.lottery {
    .userInfo {
        display: flex;
        > div {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin-left: 12px;
        }
    }
}
</style>
