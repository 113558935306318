<template>
    <el-drawer :title="title" :visible.sync="isShow" size="1000px" :before-close="onClose" @opened="onOpened">
        <el-empty v-if="isEmpty" description="暂无数据" />
        <div v-else v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.3)">
            <div class="charts_container">
                <div ref="mountNode"></div>
            </div>
            <el-table :data="tableData" stripe border>
                <el-table-column header-align="center" prop="title" label="课程" />
                <el-table-column align="center" prop="crestValueTime" label="在线峰值" width="160" />
                <el-table-column align="center" prop="crestValue" label="峰值人数" width="100" />
                <el-table-column align="center" prop="firstSellTime" label="第一次推商品时间" width="160" />
            </el-table>
        </div>
    </el-drawer>
</template>

<script>
import axios from 'axios'
import dayjs from 'dayjs'
import { getOssRamToken } from '@/js/api/commonApi'
import { getCourseExtraQuery, getCoursePackageMappingList, getCourseList } from '@/js/api/courseApi.js'

export default {
    props: ['isShow', 'activeItem'],
    data() {
        return {
            title: '',

            tableData: [],
            isEmpty: false,
            loading: false,
        }
    },
    methods: {
        onOpened() {
            this.title = '出勤数据'
            this.tableData = []
            this.isEmpty = false
            this.loading = false
            if (this.activeItem) this.title = `${this.activeItem.title} - 出勤数据`
            this.initData()
        },
        onClose() {
            if (this.line) this.line.dispose()
            this.$emit('onClose')
        },
        initChartTable(result) {
            const _legendData = []
            const _xAxisData = []
            const _seriesList = []
            result.forEach((item) => {
                _legendData.push(item.course.title)
                // 推课时间点
                if (item.firstSellTime) _xAxisData.push(item.firstSellTime.substr(11))
                // 获取时间点 (只获取当天的时间点)
                if (item.data) {
                    let _startDay = null
                    Object.keys(item.data).forEach((t) => {
                        const _day = t.substr(0, 10)
                        if (!_startDay) _startDay = _day
                        if (_startDay === _day) {
                            const _s = t.substr(11)
                            if (!_xAxisData.includes(_s)) _xAxisData.push(_s)
                        }
                    })
                }
            })

            _xAxisData.sort((a, b) => dayjs(`2021-01-01 ${a}`).valueOf() - dayjs(`2021-01-01 ${b}`).valueOf())

            const _tableData = []
            result.forEach((item) => {
                let _maxValTime = ''
                let _maxVal = 0
                const list = []

                const _attendData = Object.keys(item.data)
                if (_attendData.length !== 0) {
                    let _isEndTime = false
                    _xAxisData.forEach((time) => {
                        let val = 0
                        const _time = `${_attendData[0].substr(0, 10)} ${time}`
                        if (item.data[_time] && !_isEndTime) {
                            val = item.data[_time]
                        } else if (list.length > 0 && !_isEndTime) {
                            val = list[list.length - 1]
                        }
                        if (_attendData[_attendData.length - 1] === _time) {
                            _isEndTime = true
                        }
                        if (val > _maxVal) {
                            _maxVal = val
                            _maxValTime = _time
                        }
                        list.push(val)
                    })
                }

                // 第一次卖课时间点
                const _index = _xAxisData.indexOf(item.firstSellTime.substr(11))

                _seriesList.push({
                    name: item.course.title,
                    type: 'line',
                    showSymbol: false,
                    data: list,
                    markPoint: {
                        data: [
                            { type: 'max', name: 'Max', symbol: 'pin' },
                            {
                                coord: _index === -1 ? [] : [_index, item.firstSellTime.substr(11)],
                                value: '第一次\n卖课',
                                symbol: 'pin',
                                symbolSize: 100,
                            },
                        ],
                    },
                })

                _tableData.push({
                    title: item.course.title,
                    crestValue: _maxVal,
                    crestValueTime: _maxValTime || '-',
                    firstSellTime: item.firstSellTime || '-',
                })
            })
            this.tableData = _tableData

            this.line = window.echarts.init(this.$refs.mountNode)
            this.line.setOption({
                tooltip: { trigger: 'axis' },
                grid: { left: 0, right: 0, bottom: 0, containLabel: true },
                legend: { data: _legendData },
                xAxis: { type: 'category', name: '时间', data: _xAxisData },
                yAxis: { type: 'value' },
                series: _seriesList,
            })
        },

        async initData() {
            this.loading = true
            const res1 = await Promise.all([
                getOssRamToken({ bucketType: 'RES' }),
                getCoursePackageMappingList({ packageId: this.activeItem.id, showPage: false }),
            ])
            // oss 信息
            this.ossInfo = res1[0].data
            // 公开课下 未配置课程
            if (res1[1].data.length === 0) {
                this.isEmpty = true
                this.loading = false
                return
            }

            // 查询课程出勤信息
            const _courseIds = res1[1].data.map((i) => i.courseId)
            const res2 = await Promise.all([
                getCourseList({ ids: _courseIds }),
                getCourseExtraQuery({ courseIds: _courseIds, extraTypes: ['firstSellTime'] }),
            ])

            const _courseList = res2[0].data
            // 第一次推商品时间
            const _timeMap = {}
            res2[1].data.forEach((item) => {
                _timeMap[item.courseId] = item.extraValue
            })
            const _firstSellTimeMap = _timeMap

            // 查询课程出勤数据
            const _apiList = _courseList.map((item) => {
                return new Promise((resolve) => {
                    let url = `https://${this.ossInfo.customHost}/studentAttend/liveId/${item.liveId}`
                    axios({ method: 'GET', url: url })
                        .then((res) => {
                            resolve({ data: res.data, course: item })
                        })
                        .catch(() => {
                            resolve({ course: item })
                        })
                })
            })

            const _attend = await Promise.all(_apiList)
            this.loading = false
            _attend.forEach((item) => {
                item.data = item.data || {}
                item.firstSellTime = _firstSellTimeMap[item.course.id] || ''
            })
            // 数据处理 绘制图表
            this.$nextTick(() => {
                this.initChartTable(_attend)
            })
        },
    },
}
</script>

<style lang="less" scoped>
.charts_container {
    width: 100%;
    height: 420px;
    background: #efefef;
    margin-bottom: 24px;
    > div {
        width: 100%;
        height: 100%;
    }
}
</style>
