<template>
    <el-drawer title="出勤数据" :visible.sync="isShow" size="800px" :before-close="onClose" @opened="onOpened">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item" style="width: 50%">
                    <span class="condition_content">
                        <el-input
                            placeholder="请输入内容"
                            @change="onQueryTypeValueChange"
                            v-model.trim="queryTypeValue"
                            @keyup.enter.native="onPaginationChange('page', 1)"
                        >
                            <el-select class="query_type" v-model="queryType" slot="prepend" placeholder="请选择">
                                <el-option label="学员昵称" value="nickname"></el-option>
                                <el-option label="学员ID" value="userId"></el-option>
                            </el-select>
                        </el-input>
                    </span>
                </div>
            </div>
            <div class="opt_container">
                <el-button @click="onPaginationChange('page', 1)" type="primary">查询</el-button>
            </div>
        </div>
        <el-table :data="tableData" stripe border>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column align="center" label="微信信息">
                <template slot-scope="scope">
                    <UserView v-if="scope.row.user" :key="scope.row.id" :userInfo="scope.row.user" />
                    <span v-else>{{ scope.row.userId }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="durationText" label="听课时长" width="100" />
            <el-table-column align="center" prop="joinTime" width="96" label="进入时间" />
            <el-table-column header-align="center" prop="os" label="设备" width="120" />
        </el-table>

        <el-pagination
            background
            :page-sizes="[10, 20, 50, 100]"
            @size-change="(val) => onPaginationChange('size', val)"
            @current-change="(val) => onPaginationChange('page', val)"
            :current-page.sync="page"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        />
    </el-drawer>
</template>

<script>
import UserView from '@/views/components/UserView'
import { getAttendList } from '@/js/api/courseApi.js'
import { filterQueryEmpty, getAttendDurationText } from '@/js/utils.js'

export default {
    components: { UserView },
    props: ['isShow', 'activeCourse'],
    data() {
        return {
            queryType: 'nickname',
            queryTypeValue: '',

            tableData: [],
            page: 1,
            pageSize: 10,
            total: 0,
        }
    },
    methods: {
        onOpened() {
            this.getAttendListApi()
        },
        onClose() {
            this.queryTypeValue = ''
            this.$emit('onClose')
        },
        onQueryTypeValueChange() {
            if (this.queryType === 'userId' && !/^\d+?$/.test(this.queryTypeValue)) {
                this.queryTypeValue = ''
                this.$message.warning('用户id查询时，请输入数字')
            }
        },
        onPaginationChange(type, value) {
            if (type === 'size') {
                this.pageSize = value
                this.page = 1
            } else {
                this.page = value
            }
            this.$nextTick(this.getAttendListApi)
        },
        getAttendListApi() {
            const { page, pageSize } = this
            let params = {
                page,
                size: pageSize,
                courseId: this.activeCourse.id,
                orderBy: '-id',
                showPage: true,
            }
            params[this.queryType] = this.queryTypeValue
            params = filterQueryEmpty(params)
            getAttendList(params).then((res) => {
                res.data.list.forEach((item) => {
                    item.durationText = getAttendDurationText(item.duration)
                })
                if (res.data.total === 0) {
                    this.$message.warning('暂无数据')
                }
                this.tableData = res.data.list
                this.total = res.data.total
                this.page = res.data.pageNum
            })
        },
    },
}
</script>

<style lang="less" scoped>
.query_type {
    width: 100px;
}
</style>
