<template>
    <div class="page_container">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_label">项目</span>
                    <span class="condition_content">
                        <ProjectSelect @change="onProjectNoChange" />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">课程时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            v-model="courseTimes"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            :pickerOptions="pickerOptions"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label" style="width: 100px">公开课标题</span>
                    <span class="condition_content">
                        <el-input
                            placeholder="请输入内容"
                            v-model.trim="title"
                            clearable
                            @keyup.enter.native="onPaginationChange('page', 1)"
                        />
                    </span>
                </div>
            </div>
            <div class="opt_container">
                <el-button @click="onReset">重置</el-button>
                <el-button type="primary" @click="onPaginationChange('page', 1)">查询</el-button>

                <el-button
                    v-if="P(1030101)"
                    class="float_left_btn"
                    type="primary"
                    plain
                    @click="onShowEditPackage(null)"
                >
                    新建
                </el-button>
            </div>
        </div>
        <el-table :data="tableData" stripe border>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column align="center" prop="packageNo" label="编号" width="80" />
            <el-table-column align="center" label="封面" width="160">
                <template slot-scope="scope">
                    <el-image
                        style="width: 120px"
                        fit="cover"
                        :src="scope.row.bannerImageUrl"
                        :preview-src-list="[scope.row.bannerImageUrl]"
                    />
                </template>
            </el-table-column>
            <el-table-column header-align="center" prop="title" label="标题">
                <template slot-scope="scope">
                    <el-link :href="scope.row.previewUrl" target="_blank" type="primary" :underline="false">
                        {{ scope.row.title }}
                    </el-link>
                </template>
            </el-table-column>
            <el-table-column align="center" label="课程数">
                <template slot-scope="scope">
                    <span>{{ scope.row.courses ? scope.row.courses.length : 0 }}</span>
                </template>
            </el-table-column>

            <el-table-column header-align="center" prop="remark" label="备注" min-width="100" />
            <el-table-column align="center" width="100" label="操作" fixed="right">
                <template slot-scope="scope">
                    <div class="mini_link">
                        <el-link @click="onShowCourseDrawer(scope.row)" type="primary">课程详情</el-link>
                        <el-dropdown
                            @command="onHandleCommand"
                            v-if="P(1030101) || P(1030102) || P(1030103) || P(1030105)"
                        >
                            <el-link type="info">更多操作</el-link>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-if="P(1030103)" :command="{ ...scope.row, command: 'receive' }">
                                    选课数据
                                </el-dropdown-item>
                                <el-dropdown-item v-if="P(1030105)" :command="{ ...scope.row, command: 'attendance' }">
                                    直播间数据
                                </el-dropdown-item>
                                <el-dropdown-item v-if="P(1030101)" :command="{ ...scope.row, command: 'edit' }">
                                    编辑公开课
                                </el-dropdown-item>
                                <el-dropdown-item
                                    v-if="P(1030102)"
                                    style="color: red"
                                    :command="{ ...scope.row, command: 'delete' }"
                                >
                                    删除公开课
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            background
            :page-sizes="[10, 20, 50, 100]"
            @size-change="(val) => onPaginationChange('size', val)"
            @current-change="(val) => onPaginationChange('page', val)"
            :current-page.sync="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, sizes"
            :total="total"
        />

        <EditPackage
            :isShow="isShowEditPackage"
            :projectNo="projectNo"
            :activeItem="activeItem"
            @onClose="onCloseEditPackage"
        />
        <CourseDrawer :isShow="isShowCourseDrawer" :activeItem="activeItem" @onClose="onCloseCourseDrawer" />
        <ReceiveDetail :isShow="isShowReceiveDetail" :activeItem="activeItem" @onClose="onCloseReceiveDetail" />

        <LiveReport :isShow="isShowLiveReport" :activeItem="activeItem" @onClose="onCloseLiveReport" />
    </div>
</template>
<script>
import EditPackage from './components/publicCourse/EditPackage.vue'
import CourseDrawer from './components/publicCourse/CourseDrawer.vue'
import ReceiveDetail from './components/publicCourse/ReceiveDetail.vue'
import LiveReport from './components/publicCourse/LiveReport.vue'
import ProjectSelect from '@/views/components/ProjectSelect'
import { getPulicCoursePackageList, delCoursePackage } from '@/js/api/courseApi.js'
import { filterQueryEmpty } from '@/js/utils.js'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
    computed: { ...mapGetters(['BEGONIA_HOST', 'userInfo']) },
    components: { EditPackage, CourseDrawer, ReceiveDetail, LiveReport, ProjectSelect },
    data() {
        return {
            projectNo: '',
            pickerOptions: {
                shortcuts: [
                    {
                        text: '昨天',
                        onClick(picker) {
                            const day = dayjs().subtract(1, 'day').toDate()
                            picker.$emit('pick', [day, day])
                        },
                    },
                    {
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', [dayjs().toDate(), dayjs().toDate()])
                        },
                    },
                    {
                        text: '明天',
                        onClick(picker) {
                            const day = dayjs().add(1, 'day').toDate()
                            picker.$emit('pick', [day, day])
                        },
                    },
                ],
            },
            title: '',
            courseTimes: [],

            tableData: [],
            page: 1,
            pageSize: 10,
            total: 0,

            activeItem: null,
            isShowEditPackage: false,
            isShowCourseDrawer: false,
            isShowReceiveDetail: false,
            isShowLiveReport: false,
        }
    },
    methods: {
        onReset() {
            this.title = ''
            this.courseTimes = []
            this.onPaginationChange('page', 1)
        },
        onProjectNoChange(val) {
            this.projectNo = val
            this.onReset()
        },

        onPaginationChange(type, value) {
            if (type === 'size') {
                this.pageSize = value
                this.page = 1
            } else {
                this.page = value
            }
            this.$nextTick(this.getPulicCoursePackageListApi)
        },

        onHandleCommand(item) {
            if (item.command === 'edit') {
                this.onShowEditPackage(item)
            } else if (item.command === 'delete') {
                this.$confirm(item.title, '删除以下课程吗？', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                })
                    .then(() => {
                        delCoursePackage({ id: item.id }).then(() => {
                            this.$message.success('删除成功')
                            this.getPulicCoursePackageListApi()
                        })
                    })
                    .catch(() => {})
            } else if (item.command === 'attendance') {
                this.onShowLiveReport(item)
            } else if (item.command === 'receive') {
                this.onShowReceiveDetail(item)
            }
        },

        //课程包编辑
        onShowEditPackage(item) {
            this.activeItem = item
            this.isShowEditPackage = true
        },
        onCloseEditPackage(state) {
            this.activeItem = null
            this.isShowEditPackage = false
            if (state) this.getPulicCoursePackageListApi()
        },

        // 课程详情
        onShowCourseDrawer(item) {
            this.activeItem = item
            this.isShowCourseDrawer = true
        },
        onCloseCourseDrawer(state) {
            this.activeItem = null
            this.isShowCourseDrawer = false
            if (state) this.getPulicCoursePackageListApi()
        },

        // 选课详情
        onShowReceiveDetail(item) {
            this.activeItem = item
            this.isShowReceiveDetail = true
        },
        onCloseReceiveDetail() {
            this.activeItem = null
            this.isShowReceiveDetail = false
        },

        // 出勤数据
        onShowLiveReport(item) {
            this.activeItem = item
            this.isShowLiveReport = true
        },
        onCloseLiveReport() {
            this.activeItem = null
            this.isShowLiveReport = false
        },

        getPulicCoursePackageListApi() {
            const { page, pageSize, courseTimes, title } = this
            let params = {
                orderBy: '-id',
                page,
                size: pageSize,
                showPage: true,
                title,
                projectNo: this.projectNo,
                ptypes: ['OPEN_LIVE_ROUND'],
            }

            if (courseTimes && courseTimes.length !== 0) {
                params.courseTimes = courseTimes
            }

            params = filterQueryEmpty(params)
            getPulicCoursePackageList(params).then((res) => {
                res.data.list.forEach((item) => {
                    item.previewUrl = `${this.BEGONIA_HOST}/course.html?packageNo=${item.packageNo}&sn=${this.userInfo.staffNo}`
                })
                this.tableData = res.data.list
                this.page = res.data.pageNum
                this.total = res.data.total
            })
        },
    },
}
</script>
