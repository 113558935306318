<template>
    <el-dialog
        :title="activeCourse ? activeCourse.title : ''"
        :visible.sync="isShow"
        width="480px"
        :before-close="onClose"
        @opened="onOpened"
    >
        <el-form ref="formData" v-if="activeCourse && formData" :model="formData" :rules="rules" label-width="100px">
            <el-form-item label="标题">
                <span style="font-weight: 600">{{ activeCourse.title }}</span>
            </el-form-item>
            <el-form-item label="开始时间">
                <span style="font-weight: 600">{{ activeCourse.startTime }}</span>
            </el-form-item>
            <el-form-item label="结束时间">
                <span style="font-weight: 600">{{ activeCourse.endTime }}</span>
            </el-form-item>
            <el-form-item label="直播状态">
                <el-tag effect="dark" type="info" v-if="activeCourse.status === 0">未开始</el-tag>
                <el-tag effect="dark" type="danger" v-else-if="activeCourse.status === 1">直播中</el-tag>
                <el-tag effect="dark" type="warning" v-else>已结束</el-tag>
            </el-form-item>
            <el-form-item label="过期时间" prop="expireTime">
                <el-date-picker
                    v-model="formData.expireTime"
                    type="datetime"
                    placeholder="选择开始时间"
                    value-format="yyyy-MM-dd HH:mm:ss"
                />
            </el-form-item>
            <el-form-item label="试听人数" prop="limitCnt">
                <el-input-number v-model="formData.limitCnt" :min="1" :precision="0" />
            </el-form-item>
            <el-form-item>
                <el-button :type="activeCourse.status <= 1 ? 'primary' : ''" plain @click="getCourseAuditionUrlApi(1)">
                    直播地址
                </el-button>
                <el-button :type="activeCourse.status > 1 ? 'primary' : ''" plain @click="getCourseAuditionUrlApi(3)">
                    重播地址
                </el-button>
            </el-form-item>
            <el-form-item label="直播地址" v-if="liveUrl">
                <div class="course_url">
                    <el-input readonly v-model="liveUrl" />
                    <span @click="onCopyUrl(liveUrl)">
                        <i class="el-icon-document-copy"></i>
                    </span>
                </div>
            </el-form-item>
            <el-form-item label="重播地址" v-if="replayUrl">
                <div class="course_url">
                    <el-input readonly v-model="replayUrl" />
                    <span @click="onCopyUrl(replayUrl)">
                        <i class="el-icon-document-copy"></i>
                    </span>
                </div>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
import { getCourseAuditionUrl } from '@/js/api/courseApi.js'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
    props: ['isShow', 'packageInfo', 'activeCourse'],
    computed: { ...mapGetters(['userInfo']) },
    data() {
        return {
            formData: null,
            rules: {
                expireTime: [{ required: true, message: '请选择过期时间', trigger: 'blur' }],
            },

            liveUrl: '',
            replayUrl: '',
        }
    },

    methods: {
        onOpened() {
            this.formData = {
                expireTime: dayjs().add(1, 'day').format('YYYY-MM-DD HH') + ':00:00',
                limitCnt: 1,
            }
            if (dayjs().add(1, 'day').isBefore(dayjs(this.activeCourse.endTime))) {
                this.formData.expireTime = dayjs(this.activeCourse.endTime).format('YYYY-MM-DD HH:mm:ss')
            }
        },
        onClose() {
            this.liveUrl = ''
            this.replayUrl = ''
            this.formData = null
            this.$emit('onClose')
        },
        onCopyUrl(url) {
            window.navigator.clipboard.writeText(url).then(() => {
                this.$message.success('复制成功')
            })
        },
        getCourseAuditionUrlApi(liveStatus) {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    getCourseAuditionUrl({
                        staffId: this.userInfo.id,
                        courseId: this.activeCourse.id,
                        packageId: this.packageInfo.id,
                        expireTime: this.formData.expireTime,
                        limitCnt: this.formData.limitCnt,
                        liveStatus,
                    }).then((res) => {
                        if (liveStatus === 1) {
                            this.liveUrl = res.data
                        } else if (liveStatus === 3) {
                            this.replayUrl = res.data
                        }
                    })
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
.course_url {
    display: flex;
    .el-input {
        flex-grow: 1;
    }
}
</style>
