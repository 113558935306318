<template>
    <div>
        <el-upload action="http" accept="video/*" :before-upload="onVideoUpload" :show-file-list="false">
            <el-link v-if="videoUrl" type="primary" @click.stop="onPlayVideo">
                <i class="el-icon-video-camera"></i>
            </el-link>
            <el-button plain size="small" type="primary">上传视频</el-button>
            <i @click.stop="onClearVideo" v-if="videoUrl" class="el-icon-close"></i>
            <div class="el-upload__tip" slot="tip"><slot></slot></div>
        </el-upload>
        <el-dialog
            title="视频上传中"
            width="220px"
            top="30vh"
            :center="true"
            :visible.sync="loadingDialog"
            @close="onCloseLoadingDialog"
            :append-to-body="true"
        >
            <div style="text-align: center">
                <el-progress style="" type="circle" :percentage="uploadProgress"></el-progress>
            </div>
        </el-dialog>
        <MediaPreview :mediaInfo="mediaInfo" />
    </div>
</template>
<script>
import { UPLOAD_FILE } from '@/js/aliOss.js'
import { getVideoDuration } from '@/js/utils.js'

export default {
    props: ['videoUrl'],
    data() {
        return {
            uploadProgress: 0,

            loadingDialog: false,
            mediaInfo: null,
        }
    },
    methods: {
        onClearVideo() {
            this.$emit('change', { url: '', duration: 0 })
        },
        onPlayVideo() {
            this.mediaInfo = { url: this.videoUrl, type: 'video' }
        },
        onVideoUpload(file) {
            this.loadingDialog = true
            this.uploadFile = UPLOAD_FILE({
                bucket: 'media',
                file,
                loading: false,
                path: 'media/',
                progress: (p) => {
                    this.uploadProgress = p
                },
                success: async (res) => {
                    this.$message.success('上传成功')
                    this.loadingDialog = false
                    const duration = await getVideoDuration(res.url)
                    this.$emit('change', { url: res.url, duration })
                    this.uploadProgress = 0
                },
                error: () => {
                    this.uploadProgress = 0
                    this.loadingDialog = false
                    this.$message.error('上传失败')
                },
                cancel: () => {
                    this.uploadProgress = 0
                    this.loadingDialog = false
                    this.$message.warning('取消上传')
                },
            })
            return false
        },
        onCloseLoadingDialog() {
            if (this.uploadFile) this.uploadFile.cancel()
        },
    },
}
</script>
<style lang="less" scoped>
.el-icon-video-camera {
    margin: 0 12px;
    font-size: 22px;
}
.el-icon-close {
    margin: 0 16px;
    font-size: 18px;
}
</style>
